import React, { useContext, createContext } from "react";
import {
  CurrentUser,
  MainProfileFragment,
  SpaceMetadataFragment,
  UserPreferencesFragment,
} from "~/graphql/__generated";
import { useRevalidator } from "@remix-run/react";

export type User = Omit<MainProfileFragment, "__typename"> &
  Omit<CurrentUser, "__typename">;

export const MainContext = React.createContext<{
  authenticated: boolean;
  currentSpace: SpaceMetadataFragment;
  user?: User;
  tags: string[];
  updateTheme: (theme: "" | "light" | "dark") => void;
}>({
  currentSpace: {
    id: "",
    name: "",
    uniqueName: "",
    icon: "",
    images: {
      thumbnail: "",
    },
    modules: {
      objectives: false,
      events: false,
      resources: true,
      programs: false,
      sandbox: false,
    },
  },
  authenticated: false,
  tags: [],
  updateTheme: () => {},
});

export const useCurrentSpace = () => useContext(MainContext).currentSpace;
export const useIsAuthenticated = () => useContext(MainContext).authenticated;
export const useUser = () => useContext(MainContext).user;
export const useTags = () => useContext(MainContext).tags;
export const useUpdateTheme = () => useContext(MainContext).updateTheme;
