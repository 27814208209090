import favicon16 from "../public/favicon-16x16.png";
import favicon32 from "../public/favicon-32x32.png";
import client, { getServerClient } from "./graphql";
import {
  ContextAuthenticatedDocument,
  ContextAuthenticatedQuery,
  ContextAuthenticatedQueryVariables,
  ContextNotAuthenticatedDocument,
  useSetUserPreferencesMutation,
} from "./graphql/__generated";
import { ConfigContext, WebConfig } from "./hooks/webconfig";
import styles from "./tailwind.css?url";
import { ApolloProvider } from "@apollo/client";

import { json, LoaderFunctionArgs, redirect } from "@remix-run/node";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { withSentry } from "@sentry/remix";
import React, { useEffect, useState } from "react";
import IntercomInitializer from "~/components/intercom-initializer";
import { GenericBoundary } from "~/components/error-boundaries/error-boundaries";
import {
  parseCookie,
  rootGetAccessToken,
} from "~/utils/ensure-authenticated.server";

import {
  accessTokenCookie,
  attributionCookie,
  csrfProtectionCookie,
  getAttributionTrackingData,
  loggedInCookie,
  refreshTokenCookie,
} from "~/utils/oauth.server";
import { MainContext, User } from "~/hooks/context-hook";
import {
  AuthCheckProvider,
  currentSpaceUniqueName,
} from "~/hooks/check-authenticated";

import { Toaster } from "@mindstonehq/ui/components/ui/toaster";
import CommandPalette from "~/components/command-palette/command-palette";
import * as Sentry from "@sentry/remix";
import metrics from "./utils/metrics";
import { TooltipProvider } from "@mindstonehq/ui/components/ui/tooltip";

export function links() {
  return [
    { rel: "icon", type: "image/png", href: "/favicon.ico" },
    { rel: "icon", type: "image/png", sizes: "32x32", href: favicon32 },
    { rel: "icon", type: "image/png", sizes: "16x16", href: favicon16 },
    { rel: "stylesheet", href: styles },
    { rel: "stylesheet", href: "https://rsms.me/inter/inter.css" },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap",
    },
  ];
}

export async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const accessTokenResult = await rootGetAccessToken(request);
  let accessToken = "";
  let isNewAccessToken = false;
  if (accessTokenResult) {
    accessToken = accessTokenResult.accessToken;
    isNewAccessToken = accessTokenResult.new;
  }
  const uniqueName = currentSpaceUniqueName(request);

  let context = {};
  if (accessToken) {
    context = {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      },
    };
  }

  let configResult: ContextAuthenticatedQuery;

  try {
    const { data, error } = await getServerClient().query<
      ContextAuthenticatedQuery,
      ContextAuthenticatedQueryVariables
    >({
      query: accessToken
        ? ContextAuthenticatedDocument
        : ContextNotAuthenticatedDocument,
      fetchPolicy: "no-cache",
      variables: {
        identifier: uniqueName,
      },
      context,
    });
    if (error || !data) {
      console.error("error getting context", error);
      throw "Something went wrong";
    }
    configResult = data;
  } catch (e: any) {
    console.error("error getting context", e);
    if (url.pathname.includes("/events") && url.pathname.includes("/join")) {
      if (accessToken && e?.message?.includes("forbidden.mindspace")) {
        const url = new URL(request.url);
        const host = url.host.replace(`${uniqueName}.`, "app.");
        return redirect(`https://${host}/event-no-access`, 302);
      }

      const redirectUrl = url.toString().replace("http://", "https://");
      return redirect(
        `/signup?onlineEventRedirect=true&redirectUrl=${encodeURIComponent(redirectUrl)}`,
        302,
      );
    }
    if (url.pathname.includes("/calendar-export")) {
      const redirectUrl = url.toString().replace("http://", "https://");
      return redirect(
        `/login?redirectUrl=${encodeURIComponent(redirectUrl)}`,
        302,
      );
    }
    return redirect(
      `/login?redirectUrl=${encodeURIComponent(url.toString())}`,
      302,
    );
  }

  const config = configResult.config;

  // Attribution tracking
  const attributionData = getAttributionTrackingData(request.url);
  const headers = new Headers();
  if (attributionData) {
    headers.append(
      "Set-Cookie",
      await attributionCookie.serialize(attributionData),
    );
  }
  if (isNewAccessToken) {
    headers.append("Set-Cookie", accessTokenCookie(accessToken));
    headers.append("Set-Cookie", loggedInCookie());
    //TODO: IN a few months, remove the refresh token stuff below. This was to migrate cookies to lax mode
    const cookieHeader = request.headers.get("Cookie");
    if (cookieHeader) {
      const parsedCookie = parseCookie(cookieHeader);
      if (parsedCookie["x-ms-refresh-token"]) {
        headers.append(
          "Set-Cookie",
          refreshTokenCookie(parsedCookie["x-ms-refresh-token"]),
        );
      }
    }
  }
  headers.append("Set-Cookie", csrfProtectionCookie(request));

  if (!accessToken) {
    // User is not authenticated. If a public Mindspace, we allow to go through, otherwise
    // we redirect to login
    if (!configResult.mindspace.configuration?.public) {
      return redirect(
        `/login?redirectUrl=${encodeURIComponent(url.toString())}`,
        302,
      );
    }
    // TODO: Move join URL to faciliate it being unauthenticated
    // TODO: Investigate why this is http here
    if (url.pathname.includes("/events") && url.pathname.includes("/join")) {
      const redirectUrl = url.toString().replace("http://", "https://");
      return redirect(
        `/signup?onlineEventRedirect=true&redirectUrl=${encodeURIComponent(redirectUrl)}`,
        302,
      );
    }

    // We're good and we can stay here. Some pages will be behind signup wall.
  } else {
    if (!configResult.myProfile?.username) {
      const appUrl = url.hostname.replace(uniqueName, "app");
      return redirect(
        `https://${appUrl}/onboarding?redirectUrl=${encodeURIComponent(url.toString())}`,
        302,
      );
    }
  }

  return json(
    {
      config: {
        API: config.api,
        graphqlAPI: process.env.GRAPHQL_API,
        appUrl: `https://${url.hostname}`,
        communityUrl: config.communityUrl,
        rudderstackURL: config.rudderstack?.url,
        rudderstackWriteKeyWebApp: config.rudderstack?.writeKey,
        revision: process.env.REVISION,
        oauthAppleRedirectUrl: config.oauth?.apple.replaceAll(
          "app.",
          "identity.",
        ),
        oauthGoogleRedirectUrl: config.oauth?.google.replaceAll(
          "app.",
          "identity.",
        ),
        oauthLinkedinRedirectUrl: config.oauth?.linkedIn.replaceAll(
          "app.",
          "identity.",
        ),
        intercomAppId: config.intercom,
        environment: config.environment,
        growthbookKey: config.growthbookKey,
        googleApiKey: config.googleMapKey,
      } as WebConfig,
      preferences: configResult.currentUser?.preferences || { theme: "" },
      currentSpace: configResult.mindspace,
      user:
        configResult.myProfile && configResult.currentUser
          ? {
              ...configResult.myProfile,
              ...configResult.currentUser,
            }
          : undefined,
      tags:
        (configResult.getUserTags?.tags
          .filter((x) => x)
          .map((x) => x?.name) as string[]) || [],
    },
    {
      headers,
    },
  );
}
function App() {
  const {
    config: webConfig,
    currentSpace,
    user,
    tags,
    preferences,
  } = useLoaderData<typeof loader>();
  const [updateUserPreference] = useSetUserPreferencesMutation({
    client: client,
  });
  const [theme, setTheme] = useState(() => {
    return preferences.theme || "light";
  });

  useEffect(() => {
    metrics.init(webConfig);
  }, [webConfig]);

  useEffect(() => {
    if (!preferences.theme) {
      setTheme(
        window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "light",
      );
    }

    if (user) {
      Sentry.getCurrentScope().setUser({ id: user.id });
      if (user.email) {
        metrics.addEmail(
          user.id,
          user.email,
          !!currentSpace.programs?.enrolled?.total,
        );
      }
    }
    try {
      window.addEventListener("appinstalled", () => {
        metrics.track("pwa_installed");
      });
    } catch (error) {}
  }, [user, preferences]);

  return (
    <html lang="en" className={theme}>
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
        <OurScripts webConfig={webConfig} />
      </head>
      <body className="min-h-[100dvh] bg-background text-foreground">
        <MainContext.Provider
          value={{
            currentSpace,
            authenticated: !!user,
            user,
            tags,
            updateTheme: (theme: "" | "light" | "dark") => {
              updateUserPreference({
                variables: {
                  preferences: {
                    theme,
                  },
                },
              });
              if (theme === "") {
                //system them
                setTheme(
                  window.matchMedia &&
                    window.matchMedia("(prefers-color-scheme: dark)").matches
                    ? "dark"
                    : "light",
                );
                return;
              }
              setTheme(theme);
            },
          }}
        >
          <ConfigContext.Provider value={webConfig}>
            <ApolloProvider client={client}>
              <CommandPalette>
                <AuthCheckProvider>
                  <IntercomInitializer>
                    <TooltipProvider>
                      <Outlet />
                    </TooltipProvider>
                  </IntercomInitializer>
                </AuthCheckProvider>
              </CommandPalette>
            </ApolloProvider>
          </ConfigContext.Provider>
        </MainContext.Provider>
        <ScrollRestoration />
        <Scripts />
        <Toaster />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const item = localStorage.getItem("ms-theme");
    setTheme(item || "light");
  }, []);

  return (
    <html lang="en" className={theme}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + 'a3cb6uaz';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
            `,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.Intercom('boot', {
              app_id: 'a3cb6uaz',
              custom_launcher_selector: '#ms-intercom-launcher' 
           });`,
          }}
        />
      </head>
      <body className="min-h-[100dvh] bg-theme-background-grey-primary text-theme-text-primary">
        <GenericBoundary />
        <ScrollRestoration />
      </body>
    </html>
  );
}

export default withSentry(App);

const Trackers = () => {
  return (
    <>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=2676983045894747&ev=PageView&noscript=1"
        />
      </noscript>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (function Load3rdPartyScripts() {
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                      'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '2676983045894747');
              fbq('track', 'PageView');
            })()`,
        }}
      />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          _linkedin_partner_id = "3583161";
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);
       
          (function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);`,
        }}
      />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          !function(q,e,v,n,t,s){if(q.qp) return; n=q.qp=function(){n.qp?n.qp.apply(n,arguments):n.queue.push(arguments);}; n.queue=[];t=document.createElement(e);t.async=!0;t.src=v; s=document.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s);}(window, 'script', 'https://a.quora.com/qevents.js');
          qp('init', 'd8eb09b79e1e4535a6dcf54d3c33e59d');
          qp('track', 'ViewContent');`,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://q.quora.com/_/ad/d8eb09b79e1e4535a6dcf54d3c33e59d/pixel?tag=ViewContent&noscript=1"
        />
      </noscript>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
                !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);`,
        }}
      />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
          },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
          a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
          twq('config','obo6d');`,
        }}
      />
    </>
  );
};

const OurScripts = ({ webConfig }: { webConfig: WebConfig }) => {
  return (
    <>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.2.146/pdf.min.js" />
      <script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.2.146/pdf.worker.min.js" />
      <script
        type="text/javascript"
        src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `window.webConfig = ${JSON.stringify(webConfig)}`,
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `window.installPrompt = null`,
        }}
      />
    </>
  );
};
