import { cn } from "../../lib/utils";
import { isFrontend } from "../../../common/hooks";

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  const isClient = isFrontend();

  return (
    <div
      className={cn(
        "rounded-md bg-muted",
        // Only add animation class after hydration
        isClient && "animate-pulse",
        className,
      )}
      suppressHydrationWarning
      {...props}
    />
  );
}

export { Skeleton };
