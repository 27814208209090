import {
  useMotionValue,
  useTransform,
  useInView,
  animate,
  motion,
} from "framer-motion";
import React, { useRef, useEffect } from "react";

interface AnimatedCounterProps {
  to: number;
  from: number;
  duration: number;
}

export const AnimatedCounter = ({
  from,
  to,
  duration,
}: AnimatedCounterProps) => {
  const count = useMotionValue(from);
  const rounded = useTransform(count, (latest) => Math.round(latest));
  const ref = useRef(null);
  const inView = useInView(ref);

  useEffect(() => {
    if (inView) {
      animate(count, to, { duration });
    }
  }, [count, inView, to]);

  return <motion.span ref={ref}>{rounded}</motion.span>;
};
